import { createRouter, createWebHistory } from "vue-router";

let routes = [
  // Login Route
  {
    path: "/",
    redirect: "/leaderboard",
  },
  // Login Route
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/auth/login.vue"),
    meta: {
      authRequired: false,
    },
  },

  // Profile Page
  {
    path: "/profile",
    name: "Profile",
    component: () => import("@/views/profile.vue"),
    meta: {
      authRequired: true,
    },
  },

  // home page
  {
    path: "/home",
    name: "home",
    component: () => import("@/views/home/index.vue"),
    meta: {
      authRequired: true,
    },
  },
  // leader board
  {
    path: "/leaderboard",
    name: "leaderboard",
    component: () => import("@/views/leaderboard/index.vue"),
    meta: {
      authRequired: true,
    },
  },

  // transparency report
  {
    path: "/transparency-report",
    name: "transparency-report",
    component: () => import("@/views/transparency/index.vue"),
    meta: {
      authRequired: true,
    },
  },

  // 1-step
  {
    path: "/1-step",
    name: "1-step",
    component: () => import("@/views/1-step.vue"),
    meta: {
      authRequired: true,
    },
  },

  // 2-step
  {
    path: "/2-step",
    name: "2-step",
    component: () => import("@/views/2-step.vue"),
    meta: {
      authRequired: true,
    },
  },

  // direct
  {
    path: "/direct",
    name: "direct",
    component: () => import("@/views/direct.vue"),
    meta: {
      authRequired: true,
    },
  },

  // scaling
  {
    path: "/scaling",
    name: "scaling",
    component: () => import("@/views/scaling.vue"),
    meta: {
      authRequired: true,
    },
  },

  // affiliate
  {
    path: "/affiliate",
    name: "affiliate",
    component: () => import("@/views/affiliate.vue"),
    meta: {
      authRequired: true,
    },
  },

  // blog
  {
    path: "/blog",
    name: "blog",
    component: () => import("@/views/blog/index.vue"),
    meta: {
      authRequired: true,
    },
  },

  // blog add
  {
    path: "/blog/add",
    name: "blog-add",
    component: () => import("@/views/blog/blog.vue"),
    meta: {
      authRequired: true,
    },
  },

  // blog edit
  {
    path: "/blog/edit/:id",
    name: "blog-edit",
    component: () => import("@/views/blog/blog.vue"),
    meta: {
      authRequired: true,
    },
  },

  // blog category
  {
    path: "/blog/category",
    name: "blog-category",
    component: () => import("@/views/blog/category.vue"),
    meta: {
      authRequired: true,
    },
  },

  // blog tag
  {
    path: "/blog/tag",
    name: "blog-tag",
    component: () => import("@/views/blog/tag.vue"),
    meta: {
      authRequired: true,
    },
  },

  // lesson
  {
    path: "/lesson",
    name: "lesson",
    component: () => import("@/views/lesson/index.vue"),
    meta: {
      authRequired: true,
    },
  },

  // lesson add
  {
    path: "/lesson/add",
    name: "lesson-add",
    component: () => import("@/views/lesson/lesson.vue"),
    meta: {
      authRequired: true,
    },
  },

  // lesson edit
  {
    path: "/lesson/edit/:id",
    name: "lesson-edit",
    component: () => import("@/views/lesson/lesson.vue"),
    meta: {
      authRequired: true,
    },
  },

  // lesson category
  {
    path: "/lesson/category",
    name: "lesson-category",
    component: () => import("@/views/lesson/category.vue"),
    meta: {
      authRequired: true,
    },
  },

  // 1-step table
  {
    path: "/1-step-table",
    name: "1-step-table",
    component: () => import("@/views/tables/1-step.vue"),
    meta: {
      authRequired: true,
    },
  },

  // 2-step table
  {
    path: "/2-step-table",
    name: "2-step-table",
    component: () => import("@/views/tables/2-step.vue"),
    meta: {
      authRequired: true,
    },
  },

  // direct table
  {
    path: "/direct-table",
    name: "direct-table",
    component: () => import("@/views/tables/direct.vue"),
    meta: {
      authRequired: true,
    },
  },

  // lp 
  {
    path: "/lp",
    name: "lp",
    component: () => import("@/views/lp/index.vue"),
    meta: {
      authRequired: true,
    },
  },

  // lp add
  {
    path: "/lp/add",
    name: "lp-add",
    component: () => import("@/views/lp/page.vue"),
    meta: {
      authRequired: true,
    },
  },

  // lp edit
  {
    path: "/lp/edit/:id",
    name: "lp-edit",
    component: () => import("@/views/lp/page.vue"),
    meta: {
      authRequired: true,
    },
  },

  // upload images
  {
    path: "/images",
    name: "images",
    component: () => import("@/views/upload/index.vue"),
    meta: {
      authRequired: true,
    },
  },

  // upload image add
  {
    path: "/upload-image",
    name: "upload-image",
    component: () => import("@/views/upload/image.vue"),
    meta: {
      authRequired: true,
    },
  },

  // upload webp add
  {
    path: "/upload-webp",
    name: "upload-webp",
    component: () => import("@/views/upload/webp.vue"),
    meta: {
      authRequired: true,
    },
  },

  // contact inquiry
  {
    path: "/contact-inquiry",
    name: "contact-inquiry",
    component: () => import("@/views/contact-us.vue"),
    meta: {
      authRequired: true,
    },
  },

  // faq
  {
    path: "/faq",
    name: "faq",
    component: () => import("@/views/faq/index.vue"),
    meta: {
      authRequired: true,
    },
  },

  // faq add
  {
    path: "/faq/add",
    name: "faq-add",
    component: () => import("@/views/faq/faq.vue"),
    meta: {
      authRequired: true,
    },
  },

  // faq edit
  {
    path: "/faq/edit/:id",
    name: "faq-edit",
    component: () => import("@/views/faq/faq.vue"),
    meta: {
      authRequired: true,
    },
  },

  // faq category
  {
    path: "/faq/category",
    name: "faq-category",
    component: () => import("@/views/faq/category.vue"),
    meta: {
      authRequired: true,
    },
  },

  // user 
  {
    path: "/user",
    name: "User",
    component: () => import("@/views/user/index.vue"),
    meta: {
      authRequired: true,
    },
  },

  // PAGE NOT FOUND
  {
    name: "PageNotFound",
    path: "/:pathMatch(.*)*",
    component: () => import("@/views/PageNotFound.vue"),
    meta: {
      authRequired: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "",
  linkExactActiveClass: "",
});
router.beforeEach((routeTo, routeFrom, next) => {
  const authRequired = routeTo.matched.some((route) => route.meta.authRequired);
  const user = localStorage.getItem("user") ? true : false;

  if (authRequired && !user) {
    next("/login");
  } else if (!authRequired && user) {
    next("/leaderboard");
  } else {
    next();
  }
});


export default router;
