import { createApp } from "vue";
import { createPinia } from "pinia";

import App from "./App.vue";
import router from "./router";
import Antd from "ant-design-vue";
import "ant-design-vue/dist/antd.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap";
import "@/assets/sass/style.css";
import "@/assets/sass/media.css";

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";
import VueFeather from "vue-feather";
import CKEditor from "@ckeditor/ckeditor5-vue";


const app = createApp(App);
library.add(fas);
app.component("fa", FontAwesomeIcon);
app.component(VueFeather.name, VueFeather);
app.use(createPinia());
app.use(CKEditor);
app.use(Antd);
app.use(router);
app.mount("#app");
