<template>
    <!-- header start  -->
    <a-layout-header id="header" :theme="layoutStore.theme"
        :class="layoutStore.theme == 'light' ? 'bg-light' : 'layout-dark-bg'"
        class="d-flex justify-content-between align-items-center px-3">

        <vue-feather type="menu" class="trigger pointer"
            :class="layoutStore.theme == 'dark' ? 'text-white' : 'primary-text'" @click="updateCollapsed()" />

        <div class="d-flex align-items-center">
            <!-- <vue-feather class="pointer mx-2" :type="layoutStore.theme == 'light' ? 'moon' : 'sun'"
                        @click="changeTheme()" :class="layoutStore.theme !== 'light' ? 'text-white' : ''" /> -->

            <a-select class="me-2" v-model:value="layoutStore.country" style="width: 120px" @change="changeCountry()">
                <a-select-option value="global">Global</a-select-option>
                <a-select-option value="usa">USA</a-select-option>
                <a-select-option value="singapore">Singapore</a-select-option>
            </a-select>

            <!-- user menu dropdown start      -->
            <a-dropdown class="mx-2" :trigger="['click']" v-if="authStore.user">
                <a :class="layoutStore.theme == 'light' ? 'text-dark' : 'text-light'"
                    class="ant-dropdown-link text-decoration-none d-flex align-items-center justify-content-center gap-1"
                    @click.prevent>
                    <span>
                        <a-badge status="success" class="avatar-box" dot>
                            <a-avatar :src="authStore.user.profile_picture" v-if="authStore.user.profile_picture" />
                            <a-avatar style="color: #000070; background-color: #e6e6ff" v-else>
                                {{ String(authStore.user.first_name).charAt(0) }}
                            </a-avatar>
                        </a-badge>
                    </span>
                    <DownOutlined />
                </a>
                <template #overlay>
                    <a-menu class="user-menu-list">
                        <a-menu-item style="padding: 12px 12px;">
                            <div class="d-flex align-items-center">
                                <a-badge status="success" class="avatar-box" dot>
                                    <a-avatar :src="authStore.user.profile_picture"
                                        v-if="authStore.user.profile_picture" />
                                    <a-avatar style="color: #000070; background-color: #e6e6ff" v-else>
                                        {{ String(authStore.user.first_name).charAt(0) }}
                                    </a-avatar>
                                </a-badge>
                                <div class="d-flex flex-column mx-2">
                                    <span class="fw-bold text-name">{{ authStore.user.first_name }} {{
                                        authStore.user.last_name }}</span>
                                    <span class="text-type">{{ authStore.user.role }}</span>
                                </div>
                            </div>
                        </a-menu-item>
                        <a-menu-divider />
                        <!-- <a-menu-item>
                            <a href="javascript:;" class="text-decoration-none d-flex align-items-center my-1">
                                <vue-feather type="user" />
                                Profile
                            </a>
                        </a-menu-item>
                        <a-menu-item>
                            <a href="javascript:;" class="text-decoration-none d-flex align-items-center my-1">
                                <vue-feather type="settings" />
                                Settings
                            </a>
                        </a-menu-item>
                        <a-menu-item>
                            <a href="javascript:;" class="text-decoration-none d-flex align-items-center my-1">
                                <vue-feather type="bell" />
                                Notification Settings
                            </a>
                        </a-menu-item> -->
                        <!-- <a-menu-item>
                                    <a href="javascript:;" class="text-decoration-none d-flex align-items-center my-1">
                                        <vue-feather type="help-circle" />
                                        Help
                                    </a>
                                </a-menu-item> -->
                        <!-- <a-menu-divider />
                        <a-menu-item>
                            <a href="javascript:;" class="text-decoration-none d-flex align-items-center my-1">
                                <vue-feather type="trash" />
                                Trash
                            </a>
                        </a-menu-item> -->
                        <a-menu-item @click="logoutUser" class="text-danger">
                            <a href="javascript:;" class="text-decoration-none d-flex align-items-center my-1">
                                <vue-feather type="log-out" class="danger-text" />
                                Logout
                            </a>
                        </a-menu-item>
                    </a-menu>
                </template>
            </a-dropdown>
            <!-- user menu dropdown end  -->
        </div>

    </a-layout-header>
    <!-- header end  -->
</template>

<script setup>
import { defineProps, defineEmits } from "vue";
import { useAuthStore } from "@/helpers/api/authStore";
import { useLayoutStore } from "@/helpers/api/layoutStore";
import { message } from "ant-design-vue";

const layoutStore = useLayoutStore();
const authStore = useAuthStore();

const props = defineProps({
    collapsed: Boolean
});
const emit = defineEmits(['update:collapsed']);

function updateCollapsed() {
    emit('update:collapsed', !props.collapsed);
}

function logoutUser() {
    authStore.logout();
    message.success("Logout successful !!");
    window.location.href = "/login";
}

// function changeTheme() {
//     layoutStore.changeTheme(layoutStore.theme == 'light' ? 'dark' : 'light');
// }

const changeCountry = () => {
    layoutStore.changeCountry(layoutStore.country);
    window.location.reload();
}
</script>