import { defineStore } from "pinia";
export const useLayoutStore = defineStore("layout", {
  state: () => {
    return {
      theme: localStorage.theme ? localStorage.getItem("theme") : "light",
      country: localStorage.country ? localStorage.getItem("country") : "global",
    };
  },
  actions: {
    // set theme
    async changeTheme(theme) {
      localStorage.setItem("theme", theme);
      this.theme = theme;
    },

    // set country
    async changeCountry(country) {
      localStorage.setItem("country", country);
      this.country = country;
    }
  },
});