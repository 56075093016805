import { message } from "ant-design-vue";

const convertArrayToObject = (array, key) => {
  const initialValue = {};
  return array.reduce((obj, item) => {
    return {
      ...obj,
      [item[key]]: item,
    };
  }, initialValue);
};
const maintainedErrorResponse = (error) => {
  if (Array.isArray(error)) {
    return convertArrayToObject(error);
  } else {
    return error;
  }
};

const errorHandler = (error) => {
  if (error.response !== undefined || null) {
    if (error.response.status === 500) {
      message.error("Internal server error !!");
    } else if (error.response.status === 413 || error.response.status === 401) {
      // localStorage.clear();
      // sessionStorage.clear();
      if (error.response.data.detail) {
        message.error(error.response.data.detail);
      } else {
        message.error("User is Unauthorized !!");
      }
      // window.location.reload();
    } else if (error.response.status === 400 || error.response.status === 404) {
      if (error.response.data.message) {
        message.error(error.response.data.message);
      } else {
        message.error("Bad request !!");
      }
    } else if (error.response.status === 406) {
      const errors = error.response.data.data.errors || [];
      errors.forEach(err => {
        console.log(err);
        message.error(`${err.field}: ${err.message}`);
      });
    } else {
      const response = maintainedErrorResponse(error);
      Object.keys(response).map((item) => {
        message.error(response[item]);
      });
    }
  } else {
    message.error("Something went Wrong !!");
  }
};

export default errorHandler;
