import { defineStore } from "pinia";
import axios from "axios";
import errorHandler from "@/helpers/errorHandler";
const user = JSON.parse(localStorage.getItem("user"));
const token = localStorage.getItem("token");
const API_URL = process.env.VUE_APP_ROOT_API;

export const useAuthStore = defineStore("auth", {
  state: () => {
    return {
      user: user ? user : [],
      token: token ? token : null,
    };
  },
  actions: {
    // login
    async login(data) {
      this.logout();

      return await axios
      .post(API_URL + "auth/login", data)
      .then((response) => {
        if (response.data) {
            localStorage.setItem("user", JSON.stringify(response.data.data.user));
            localStorage.setItem("token", JSON.stringify(response.data.data.accessToken));
            this.user = response.data.data.user;
            this.token = response.data.data.accessToken;
        }
        return response;
      })
    },

    // register
    async register(data) {
      return await axios
      .post(API_URL + "register", data)
      .then((response) => {
        if (response.data) {
            localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      })
      .catch((error) => {
        errorHandler(error);
      });
    },

    // logout
    async logout() {
        localStorage.clear();
        localStorage.clear();
    }
  },
});